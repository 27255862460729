import React from "react";

import Layout from "./components/Layout";

import Settings from "./components/Settings";
import { showSignInAtom, showSignUpAtom } from "./utils/state";
import SignInModal from "./components/SignInModal";
import SignUpModal from "./components/SignUpModal";
import { useAtom } from "jotai";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Chart from "./pages/Chart";
import Help from "./components/Help";

function App() {
  const [showSignIn, setShowSignIn] = useAtom(showSignInAtom);
  const [showSignUp, setShowSignUp] = useAtom(showSignUpAtom);

  return (
    <Router>
      <Layout title="Position Size Calculator">
        <Header />
        <Switch>
          <Route exact path="/">
            <Chart />
          </Route>
          {/* <Route exact path="/calc">
            <Calculator />
          </Route> */}
        </Switch>
        <Help />
        <Settings />
        <SignInModal open={showSignIn} onClose={() => setShowSignIn(false)} />
        <SignUpModal open={showSignUp} onClose={() => setShowSignUp(false)} />
      </Layout>
    </Router>
  );
}

export default App;
