const LONG_LIQ_COEF = 1.011;
const SHORT_LIQ_COEF = 0.989;

const getCoef = (isLong: boolean) => (isLong ? LONG_LIQ_COEF : SHORT_LIQ_COEF);

export const riskedCapital = (capital: number, risk: number) => capital * risk;

export const liquidation = (
  entry: number,
  leverage: number,
  isLong: boolean
) => {
  const modifiedEntry = entry * getCoef(isLong);
  return isLong
    ? modifiedEntry - entry / leverage
    : modifiedEntry + entry / leverage;
};

export const positionSize = (
  riskedCapital: number,
  entry: number,
  stop: number,
  isLong: boolean
) => riskedCapital / (isLong ? entry - stop : stop - entry);

export const margin = (positionSize: number, entry: number, leverage: number) =>
  (positionSize * entry) / leverage;

export const pnl = (
  positionSize: number,
  entry: number,
  exit: number,
  isLong: boolean
) => positionSize * (isLong ? exit - entry : entry - exit);

export const roe = (pnl: number, margin: number) => (pnl / margin) * 100;

export const riskReward = (pnl: number, riskedCapital: number) =>
  pnl / riskedCapital;

export const liqError = (stop: number, liquidation: number, isLong: boolean) =>
  isLong ? stop <= liquidation : stop >= liquidation;

export const percentChange = (entry: number, exit: number, isLong: boolean) =>
  (isLong ? exit / entry - 1 : entry / exit - 1) * 100;
