import { useAtom } from "jotai";
import React from "react";
import { capitalAtom, riskAtom } from "../utils/state";
import { EuiFieldText, EuiFormRow, EuiHideFor, EuiPanel, EuiToolTip } from "@elastic/eui";
import SymbolSelect from "./SymbolSelect";

const RiskCapital: React.FC = () => {
  const [capital, setCapital] = useAtom(capitalAtom);
  const [risk, setRisk] = useAtom(riskAtom);

  return (
    <EuiPanel>
      <EuiHideFor sizes={["l", "xl"]}>
        <SymbolSelect />
      </EuiHideFor>
      <EuiFormRow
        display="columnCompressed"
        fullWidth
        label={
          <EuiToolTip content="The total capital available in your account.">
            <span>Total Capital</span>
          </EuiToolTip>
        }
      >
        <EuiFieldText
          name="capital"
          prepend="$"
          compressed
          value={capital}
          onChange={(evt) => setCapital(evt.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow
        display="columnCompressed"
        fullWidth
        label={
          <EuiToolTip content="The percentage of your total capital you are willing to risk in this trade.">
            <span>Risk</span>
          </EuiToolTip>
        }
      >
        <EuiFieldText
          name="risk"
          append="%"
          compressed
          value={risk}
          onChange={(evt) => setRisk(evt.target.value)}
        />
      </EuiFormRow>
    </EuiPanel>
  );
};

export default RiskCapital;
