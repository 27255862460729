import { EuiText } from "@elastic/eui";
import React, { useEffect, useState } from "react";
import { useCalculated, useCalculator } from "../utils/state";

const CalcError: React.FC = () => {
  const [message, setMessage] = useState("");
  const [{ longShort }] = useCalculator();
  const { longShortStopError } = useCalculated();

  useEffect(() => {
    if (longShortStopError) {
      setMessage(
        longShort === "long" ? "Stop loss must be below entry" : "Stop loss must be above entry"
      );
    } else {
      setMessage("");
    }
  }, [longShortStopError, longShort]);

  // useEffect(() => {
  //   if (marginError) {
  //     setMessage(
  //       "Insufficient account capital to make trade. Decrease risk, increase leverage, or increase your stop loss distance."
  //     );
  //   } else {
  //     setMessage("");
  //   }
  // }, [marginError, longShort]);

  return message ? (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 12,
        backgroundColor: "rgba(50, 50, 50, 0.5)",
        backdropFilter: "blur(4px)",
      }}
    >
      <EuiText color="danger">{message}</EuiText>
    </div>
  ) : null;
};

export default CalcError;
