import React, { useEffect, useRef } from "react";
import { render } from "katex";

interface FormulaProps {
  tex: string;
}

const Formula: React.FC<FormulaProps> = ({ tex }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      render(tex, ref.current, { displayMode: true });
    }
  }, [tex]);
  return <span ref={ref}></span>;
};

export default Formula;
