import { binance as Binance } from "ccxt.pro";

export const binance = new Binance({
  // apiKey: process.env.REACT_APP_BINANCE_API_KEY,
  // secret: process.env.REACT_APP_BINANCE,
  enableRateLimit: true,
  options: {
    defaultType: "future"
  }
});
