import { persistedState } from "./helpers";
import { focusAtom } from "jotai/optics";
import { atomWithStore } from "jotai/zustand";
import { atomWithProxy } from "jotai/valtio";
import { atom } from "jotai";
import { UserResult } from "userbase-js";
import { proxy } from "valtio/vanilla";
import { userbase } from "../userbase";

export const globalStore = persistedState("_cpos.global", {
  symbol: "BTC/USDT",
  interval: "15",
  capital: "1000",
  risk: "5",
  showChart: true,
});

export const globalAtom = atomWithStore(globalStore);

export const symbolAtom = focusAtom(globalAtom, (g) => g.prop("symbol"));
export const intervalAtom = focusAtom(globalAtom, (g) => g.prop("interval"));
export const capitalAtom = focusAtom(globalAtom, (g) => g.prop("capital"));
export const riskAtom = focusAtom(globalAtom, (g) => g.prop("risk"));
export const showChartAtom = focusAtom(globalAtom, (g) => g.prop("showChart"));

export const authProxy = proxy<{ user: UserResult | null }>({ user: null });

// non-persisted
export const showSettingsAtom = atom(false);
export const showSignInAtom = atom(false);
export const showSignUpAtom = atom(false);
export const showHelpAtom = atom(false);
export const helpTabId = atom("Glossary");
export const userAtom = atomWithProxy(authProxy);

export const tradesAtom = atom<any[], any[]>(
  (get) => {
    const { user } = get(userAtom);
    if (!user) {
      return [];
    }
    return [];
  },
  (_get, set, update) => {
    set(tradesAtom, update);
  }
);

tradesAtom.onMount = (set) => {
  userbase.openDatabase({
    databaseName: "trades",
    changeHandler: (items) => {
      set(items);
    },
  });
};
