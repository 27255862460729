import { atom, useAtom } from "jotai";
import { focusAtom } from "jotai/optics";
import { capitalAtom, riskAtom, symbolAtom } from "./global";
import { getNumeric, tPersistedAtom } from "./helpers";
import * as ops from "../ops";
import { useAtomValue } from "jotai/utils";

export const defaultValues = () => ({
  entry: "",
  stop: "",
  exit: "",
  leverage: "10",
  longShort: "long" as "long" | "short",
});

type CalcInput = ReturnType<typeof defaultValues>;

export const calcInputAtom = atom<CalcInput, Partial<CalcInput>>(
  (get) => {
    const symbol = get(symbolAtom);
    const jsonStr = localStorage.getItem(`_cpos.calc.${symbol}`);
    const obj = jsonStr ? JSON.parse(jsonStr) : defaultValues();
    return obj as CalcInput;
  },
  (get, set, newInput) => {
    const newState = {
      ...get(calcInputAtom),
      ...newInput,
    };
    localStorage.setItem(`_cpos.calc.${get(symbolAtom)}`, JSON.stringify(newState));
    set(calcInputAtom, newState);
  }
);

export const entryAtom = focusAtom(calcInputAtom, (i) => i.prop("entry"));
export const stopAtom = focusAtom(calcInputAtom, (i) => i.prop("stop"));
export const exitAtom = focusAtom(calcInputAtom, (i) => i.prop("exit"));
export const leverageAtom = focusAtom(calcInputAtom, (i) => i.prop("leverage"));
export const longShortAtom = focusAtom(calcInputAtom, (i) => i.prop("longShort"));
export const dcasAtom = tPersistedAtom("_cpos.dcas", [10, 15, 35, 40]);

export const calculatedAtom = atom((get) => {
  const capital = getNumeric(get(capitalAtom));
  let risk = getNumeric(get(riskAtom));

  const values = get(calcInputAtom);

  const entry = getNumeric(values.entry);
  const stop = getNumeric(values.stop);
  const leverage = getNumeric(values.leverage);
  const exit = getNumeric(values.exit);
  const dcas = get(dcasAtom);
  const isLong = values.longShort === "long";

  risk = risk ? risk / 100 : null;

  const riskedCapital = capital && risk ? ops.riskedCapital(capital, risk) : null;

  const liquidation = entry && leverage ? ops.liquidation(entry, leverage, isLong) : null;

  const positionSize =
    entry && stop && riskedCapital ? ops.positionSize(riskedCapital, entry, stop, isLong) : null;

  const margin =
    positionSize && entry && leverage ? ops.margin(positionSize, entry, leverage) : null;

  const pnl = positionSize && exit && entry ? ops.pnl(positionSize, entry, exit, isLong) : null;

  const roe = pnl && margin ? ops.roe(pnl, margin) : null;

  const riskReward = pnl && riskedCapital ? ops.riskReward(pnl, riskedCapital) : null;

  const liqError = stop && liquidation ? ops.liqError(stop, liquidation, isLong) : false;

  const percentChange = entry && exit ? ops.percentChange(entry, exit, isLong) : null;

  const dca = dcas.map((dca) => ({
    percent: dca,
    position: dca && positionSize ? (dca / 100) * positionSize : null,
  }));

  const longShortStopError = stop && entry ? (isLong ? stop > entry : stop < entry) : false;
  const marginError = margin && capital ? margin > capital : false;

  return {
    riskedCapital,
    liquidation,
    liqError,
    positionSize,
    margin,
    pnl,
    roe,
    stop,
    riskReward,
    percentChange,
    dca,
    longShortStopError,
    marginError,
  };
});

export const useCalculator = () => useAtom(calcInputAtom);
export const useCalculated = () => useAtomValue(calculatedAtom);
