import React, { FormEventHandler, useState } from "react";
import { userbase } from "../utils/userbase";
import { useUpdateAtom } from "jotai/utils";
import { userAtom } from "../utils/state";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFieldPassword,
  EuiFieldText,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";

interface SignInModalProps {
  open: boolean;
  onClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ open, onClose }) => {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const setUserAtom = useUpdateAtom(userAtom);

  const login: FormEventHandler = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    setError("");
    try {
      const user = await userbase.signUp({
        username,
        password,
        email,
        rememberMe: "local",
      });
      setUserAtom({ user });
      onClose();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return open ? (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiTitle>
          <h2>Sign Up</h2>
        </EuiTitle>
      </EuiModalHeader>
      <form onSubmit={login}>
        <EuiModalBody>
          {error && (
            <>
              <EuiCallOut color="danger">{error}</EuiCallOut>
              <EuiSpacer />
            </>
          )}
          <EuiFormRow label="Username">
            <EuiFieldText
              onChange={(evt) => setUsername((evt.target as any).value)}
              value={username}
            />
          </EuiFormRow>
          <EuiFormRow label="Email">
            <EuiFieldText
              onChange={(evt) => setEmail((evt.target as any).value)}
              value={email}
            />
          </EuiFormRow>
          <EuiFormRow label="Password">
            <EuiFieldPassword
              onChange={(evt) => setPassword((evt.target as any).value)}
              value={password}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose} type="button">
            Cancel
          </EuiButtonEmpty>
          <EuiButton isLoading={loading} type="submit">
            Sign Up
          </EuiButton>
        </EuiModalFooter>
      </form>
    </EuiModal>
  ) : null;
};

export default SignInModal;
