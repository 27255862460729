import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  title?: string;
};

const Layout = ({ children }: Props) => (
  <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
    {children}
  </div>
);

export default Layout;
