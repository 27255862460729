import { fixed } from "../utils";
import React from "react";
import { useCalculated } from "../utils/state";
import { EuiBadge, EuiCopy, EuiFormRow, EuiPanel, EuiText, EuiToolTip } from "@elastic/eui";
import { TextColor } from "@elastic/eui/src/components/text/text_color";
import CalcError from "./CalcError";

const Focus: React.FC<{ focus: boolean }> = ({ children, focus }) =>
  focus ? <EuiBadge>{children}</EuiBadge> : <>{children}</>;

const Val: React.FC<{
  label: string;
  value?: number | null;
  error?: boolean;
  description?: React.ReactNode;
  prepend?: string;
  color?: TextColor;
  append?: string;
  focus?: boolean;
}> = ({ label, error, description, focus, value, prepend, append, color }) => (
  <EuiFormRow
    label={
      <EuiToolTip content={description}>
        <span>{label}</span>
      </EuiToolTip>
    }
    display="columnCompressed"
    fullWidth
    isInvalid={error}
  >
    {!value ? (
      <EuiText textAlign="right">-</EuiText>
    ) : (
      <div style={{ textAlign: "right" }}>
        <EuiCopy textToCopy={fixed(value)}>
          {(copy) => (
            <Focus focus={focus || false}>
              <EuiText onClick={copy} textAlign="right" className="mono copyable" color={color}>
                {value ? `${prepend || ""}${fixed(value)}${append || ""}` : ""}
              </EuiText>
            </Focus>
          )}
        </EuiCopy>
      </div>
    )}
  </EuiFormRow>
);

const Calculated: React.FC = () => {
  const {
    riskedCapital,
    positionSize,
    margin,
    liquidation,
    liqError,
    pnl,
    roe: pnlPerc,
    riskReward,
    percentChange,
    marginError,
  } = useCalculated();

  return (
    <EuiPanel style={{ position: "relative" }}>
      <CalcError />
      <Val
        label="Risked Capital"
        description="Amount of capital that will be lost if your configured stop loss is reached."
        value={riskedCapital}
        prepend="$"
      />
      <Val
        label="Margin"
        description={
          <>
            <p>Total amount of your capital used in this position.</p>
            {marginError && (
              <EuiText color="danger" size="s">
                Required margin is greater than total capital.
                <br />
                Decrease risk, increase leverage, or increase the distance of your stop loss.
              </EuiText>
            )}
          </>
        }
        prepend="$"
        value={margin}
        color={marginError ? "danger" : undefined}
        error={marginError}
      />
      <Val
        label="Position Size"
        focus
        value={positionSize}
        description="Size of position in asset/derivative (not currency)"
      />
      <Val
        label="Liquidation"
        error={liqError}
        prepend="$"
        value={liquidation}
        color={liqError ? "danger" : undefined}
        description={
          <>
            <p>
              The <strong>estimated</strong> liquidation price based on your entry.
            </p>
            {liqError && (
              <EuiText color="danger" size="s">
                Liquidation point will be reached prior to stop loss.
                <br />
                Decrease leverage or decrease distance of your stop loss.
              </EuiText>
            )}
          </>
        }
      />
      <Val
        label="Risk / Reward"
        value={riskReward}
        description="Ratio of potential gains over potential losses."
        color={riskReward ? (riskReward < 1 ? "danger" : "secondary") : undefined}
      />

      <Val
        label="Estimated PnL"
        prepend="$"
        value={pnl}
        description="Potential gains if take profit is reached."
        color={pnl ? (pnl < 0 ? "danger" : "secondary") : undefined}
      />
      <Val
        label="ROE"
        value={pnlPerc}
        append="%"
        description="Return on equity"
        color={pnlPerc ? (pnlPerc < 0 ? "danger" : "secondary") : undefined}
      />
      <Val
        label="Percent Change"
        value={percentChange}
        append="%"
        description="Percent change of asset at exit price without leverage."
        color={percentChange ? (percentChange < 0 ? "danger" : "secondary") : undefined}
      />
      <EuiText size="xs" color="subdued">
        <strong>Note: </strong> does not account for exchange fees.
      </EuiText>
    </EuiPanel>
  );
};

export default Calculated;
