import { Market, OHLCV } from "ccxt";
import type { IBasicDataFeed, ResolutionString } from "../../vendor/tradingview/charting_library";
import { binance } from "../exchange";

const supportedResolutions = [
  "1",
  "5",
  "15",
  "30",
  "60",
  "240",
  "1D",
] as ResolutionString[];

const resolutionMap: Record<string, string> = {
  "1": "1m",
  "5": "5m",
  "15": "15m",
  "30": "30m",
  "60": "1h",
  "240": "4h",
  "1D": "1d",
};

function convertOhlcv(ohlcv: OHLCV) {
  const [time, open, high, low, close, volume] = ohlcv;
  return {
    time,
    open,
    high,
    low,
    close,
    volume,
  };
}

export const binanceFeed: IBasicDataFeed = (() => {
  let subscriptionActive = false;
  let markets: Market[] = [];

  const feed: IBasicDataFeed = {
    onReady: async (cb) => {
      markets = await binance.fetchMarkets();
      cb({
        supported_resolutions: supportedResolutions,
        exchanges: [
          {
            value: "Binance Futures",
            name: "Binance Futures",
            desc: "Binance Futures",
          },
        ],
      });
    },

    searchSymbols: async (input, exchange, symbolType, onResult) => {
      console.log("searchSymbols", { input, exchange, symbolType });

      onResult(
        markets
          .filter((m) => m.id.startsWith(input) || m.symbol.startsWith(input))
          .map((m) => ({
            symbol: m.symbol,
            full_name: m.symbol,
            description: m.symbol,
            exchange: exchange,
            ticker: m.symbol,
            type: "futures",
          }))
      );
    },

    resolveSymbol: async (symbolName, onResolve, onError) => {
      const market = markets.find((m) => m.id.startsWith(symbolName) || m.symbol.startsWith(symbolName));
      console.log("resolveSymbol", { symbolName, market });
      
      if (market) {
        await new Promise(resolve => setTimeout(resolve, 0));
        onResolve({
          name: market.symbol,
          full_name: market.symbol,
          description: market.symbol,
          type: "futures",
          session: "24x7",
          exchange: "Binance Futures",
          listed_exchange: "Binance Futures - Listed",
          timezone: "Etc/UTC",
          supported_resolutions: supportedResolutions,
          has_intraday: true,
          has_no_volume: false,
          format: "price",
          pricescale: Math.pow(10, market.precision.price),
          minmov: 1,
        });
      } else {
        onError("Symbol not found.");
      }
    },

    getBars: async (
      symbolInfo,
      resolution,
      rangeStartDate,
      rangeEndDate,
      onResult,
      onError,
      isFirstCall
    ) => {
      console.log("getBars", {
        symbolInfo,
        resolution,
        rangeStartDate,
        rangeEndDate,
        isFirstCall,
      });

      const ohlcv = await binance.fetchOHLCV(
        symbolInfo.name,
        resolutionMap[resolution]
      );
      if (isFirstCall) {
        onResult(ohlcv.map(convertOhlcv), { noData: false });
      } else {
        onResult([], { noData: true });
      }
    },

    subscribeBars: async (
      symbolInfo,
      resolution,
      onTick,
      listenerGuid,
      onResetCacheNeededCb
    ) => {
      console.log("subscribeBars", {
        symbolInfo,
        resolution,
        listenerGuid,
      });

      subscriptionActive = true;

      while (subscriptionActive) {
        const ohlcv = await binance.watchOHLCV(
          symbolInfo.name,
          resolutionMap[resolution]
        );
        onTick(convertOhlcv(ohlcv[ohlcv.length - 1]));
      }
    },

    unsubscribeBars: (listenerGuid) => {
      subscriptionActive = false;
    },
  };
  return feed;
})();
