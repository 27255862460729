import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiCard,
  EuiPanel,
} from "@elastic/eui";
import React from "react";
import Calculated from "../components/Calculated";
import DCA from "../components/DCA";
import RiskCapital from "../components/RiskCapital";
import TradeCard from "../components/TradeCard";
import TradingView from "../components/chart/TradingView";

const Chart: React.FC = () => {
  return (
    <EuiFlexGroup
      gutterSize="m"
      style={{ padding: 12, width: "100%", maxWidth: 1600, margin: "0 auto" }}
    >
      <EuiFlexItem grow={false} style={{ width: 350 }}>
        <RiskCapital />
        <EuiSpacer size="m" />
        <TradeCard />
        <EuiSpacer size="m" />
        <Calculated />
        <EuiSpacer size="m" />
        <EuiCard title="Dollar Cost Average" textAlign="left" titleSize="xs">
          <DCA />
        </EuiCard>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiPanel style={{ padding: 0 }}>
          <TradingView />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Chart;
