import { EuiComboBox, EuiComboBoxOptionOption, EuiFormRow } from "@elastic/eui";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { dcasAtom } from "../utils/state";

interface DCASettingProps {}

const isValid = (value: string) => {
  return Number(value) > 0;
};

const DCASetting: React.FC<DCASettingProps> = () => {
  const [isInvalid, setInvalid] = useState(false);
  const [dcas, setDcas] = useAtom(dcasAtom);

  const onCreateOption = (searchValue: string) => {
    if (!isValid(searchValue)) {
      // Return false to explicitly reject the user's input.
      return false;
    }

    setDcas([...dcas, Number(searchValue)]);
  };

  const onSearchChange = (searchValue: string) => {
    if (!searchValue) {
      setInvalid(false);

      return;
    }

    setInvalid(!isValid(searchValue));
  };

  const onChange = (selectedOptions: EuiComboBoxOptionOption<number>[]) => {
    setDcas(selectedOptions.map((o) => o.value!));
    setInvalid(false);
  };

  return (
    <EuiFormRow
      label="DCA Percentages"
      isInvalid={isInvalid}
      helpText={`Total: ${dcas.reduce((l, r) => l + r, 0)}%`}
      error={isInvalid ? "Only numbers are allowed (>0)" : undefined}
    >
      <EuiComboBox
        noSuggestions
        placeholder="10, 15, 45..."
        selectedOptions={dcas.map((d) => ({
          label: `${d.toString()}%`,
          value: d,
        }))}
        onCreateOption={onCreateOption}
        onChange={onChange}
        onSearchChange={onSearchChange}
        isInvalid={isInvalid}
      />
    </EuiFormRow>
  );
};

export default DCASetting;
