import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiHeaderSection,
} from "@elastic/eui";
import { useUpdateAtom } from "jotai/utils";
import React from "react";
import { showHelpAtom, showSettingsAtom } from "../utils/state";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const setShowSettings = useUpdateAtom(showSettingsAtom);
  const setShowHelp = useUpdateAtom(showHelpAtom);

  return (
    <EuiHeader style={{ width: "100%" }}>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <EuiHeaderLogo iconType="crosshairs" className="mono">
            calc.trade
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <EuiHeaderSectionItemButton onClick={() => setShowHelp(true)}>
            <EuiIcon type="help" />
          </EuiHeaderSectionItemButton>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiHeaderSectionItemButton onClick={() => setShowSettings(true)}>
            <EuiIcon type="menu" size="m" />
          </EuiHeaderSectionItemButton>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  );
};

export default Header;
