import React from "react";
import { useCalculator, defaultValues } from "../utils/state";
import {
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiFieldNumber,
  EuiFormRow,
  EuiPanel,
  EuiRange,
} from "@elastic/eui";

const TradeCard: React.FC = () => {
  const [values, setValues] = useCalculator();
  return (
    <EuiPanel>
      <EuiFormRow label="Type" display="columnCompressed" fullWidth>
        <EuiButtonGroup
          legend="Long or short"
          options={[
            { label: "Long", id: "long" },
            { label: "Short", id: "short" },
          ]}
          idSelected={values.longShort}
          color={values.longShort === "long" ? "secondary" : "danger"}
          onChange={(id: string) =>
            setValues({ longShort: id as "long" | "short" })
          }
          buttonSize="compressed"
          isFullWidth
        />
      </EuiFormRow>
      <EuiFormRow label="Leverage" display="columnCompressed" fullWidth>
        <EuiRange
          compressed
          value={values.leverage}
          min={1}
          max={100}
          onChange={(evt) => setValues({ leverage: (evt.target as any).value })}
          showInput
        />
      </EuiFormRow>
      <EuiFormRow label="Entry" display="columnCompressed" fullWidth>
        <EuiFieldNumber
          compressed
          value={values.entry}
          onChange={(evt) => setValues({ entry: evt.target.value })}
          prepend="$"
        />
      </EuiFormRow>
      <EuiFormRow label="Stop" display="columnCompressed" fullWidth>
        <EuiFieldNumber
          compressed
          value={values.stop}
          onChange={(evt) => setValues({ stop: evt.target.value })}
          prepend="$"
        />
      </EuiFormRow>
      <EuiFormRow label="Exit" display="columnCompressed" fullWidth>
        <EuiFieldNumber
          compressed
          value={values.exit}
          onChange={(evt) => setValues({ exit: evt.target.value })}
          prepend="$"
        />
      </EuiFormRow>
      <div style={{ textAlign: "right" }}>
        <EuiButtonEmpty size="xs" onClick={() => setValues(defaultValues())}>
          Reset
        </EuiButtonEmpty>
      </div>
    </EuiPanel>
  );
};

export default TradeCard;
