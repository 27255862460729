export function fixed(num: number | null): string {
  if (!num) {
    return "-";
  }

  if (num < 0.01) {
    return num.toFixed(5);
  }
  if (num < 0.1) {
    return num.toFixed(4);
  }
  if (num < 10) {
    return num.toFixed(3);
  }
  return num.toFixed(2);
}
