import { globalStore } from "./global";
import create from "zustand/vanilla";
import { binance } from "../exchange";
import { Market } from "ccxt";

type ExchangeStore = {
  currentMarket: Market | null;
};

export const exchangeStore = create<ExchangeStore>(() => ({
  currentMarket: null,
}));

globalStore.subscribe<string>(
  async (symbol) => {
    console.log("triggered");
    const markets = await binance.loadMarkets();
    exchangeStore.setState((prev) => ({
      ...prev,
      currentMarket: markets[symbol] || null,
    }));
  },
  (s) => s.symbol
);
