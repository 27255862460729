// eslint-disable-next-line import/no-anonymous-default-export
export default {
  layout: "s",
  charts: [
    {
      panes: [
        {
          sources: [
            {
              type: "MainSeries",
              id: "JBYrS4",
              state: {
                style: 1,
                esdShowDividends: true,
                esdShowSplits: true,
                esdShowEarnings: true,
                esdShowBreaks: false,
                esdBreaksStyle: {
                  color: "rgba( 235, 77, 92, 1)",
                  style: 2,
                  width: 1,
                },
                esdFlagSize: 2,
                showCountdown: true,
                bidAsk: {
                  visible: false,
                  lineStyle: 1,
                  lineWidth: 1,
                  bidLineColor: "#2196F3",
                  askLineColor: "#EF5350",
                },
                prePostMarket: {
                  visible: true,
                  lineStyle: 1,
                  lineWidth: 1,
                  preMarketColor: "#fb8c00",
                  postMarketColor: "#2196f3",
                },
                showInDataWindow: true,
                visible: true,
                showPriceLine: true,
                priceLineWidth: 1,
                priceLineColor: "",
                baseLineColor: "#B2B5BE",
                showPrevClosePriceLine: false,
                prevClosePriceLineWidth: 1,
                prevClosePriceLineColor: "rgba( 85, 85, 85, 1)",
                minTick: "default",
                extendedHours: false,
                dividendsAdjustment: {},
                sessVis: false,
                statusViewStyle: {
                  fontSize: 16,
                  showExchange: true,
                  showInterval: true,
                  symbolTextSource: "description",
                },
                candleStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  drawWick: true,
                  drawBorder: true,
                  borderColor: "#378658",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  wickColor: "#737375",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350",
                  barColorsOnPrevClose: false,
                  drawBody: true,
                },
                hollowCandleStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  drawWick: true,
                  drawBorder: true,
                  borderColor: "rgba( 55, 134, 88, 1)",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  wickColor: "rgba( 115, 115, 117, 1)",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350",
                  drawBody: true,
                },
                haStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  drawWick: true,
                  drawBorder: true,
                  borderColor: "rgba( 55, 134, 88, 1)",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  wickColor: "rgba( 115, 115, 117, 1)",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350",
                  showRealLastPrice: false,
                  barColorsOnPrevClose: false,
                  inputs: {},
                  inputInfo: {},
                  drawBody: true,
                },
                barStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  barColorsOnPrevClose: false,
                  dontDrawOpen: false,
                  thinBars: true,
                },
                hiloStyle: {
                  color: "#2196f3",
                  showBorders: true,
                  borderColor: "#2196f3",
                  showLabels: true,
                  labelColor: "#2196f3",
                  fontSize: 7,
                  drawBody: true,
                },
                lineStyle: {
                  color: "#2196f3",
                  linestyle: 0,
                  linewidth: 2,
                  priceSource: "close",
                  styleType: 2,
                },
                areaStyle: {
                  color1: "rgba(33, 150, 243, 0.28)",
                  color2: "#2196f3",
                  linecolor: "#2196f3",
                  linestyle: 0,
                  linewidth: 2,
                  priceSource: "close",
                  transparency: 100,
                },
                renkoStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  upColorProjection: "rgba( 169, 220, 195, 1)",
                  downColorProjection: "rgba( 245, 166, 174, 1)",
                  borderUpColorProjection: "rgba( 169, 220, 195, 1)",
                  borderDownColorProjection: "rgba( 245, 166, 174, 1)",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350",
                  inputs: {
                    source: "close",
                    sources: "Close",
                    boxSize: 3,
                    style: "ATR",
                    atrLength: 14,
                    wicks: true,
                  },
                  inputInfo: {
                    source: { name: "source" },
                    sources: { name: "Source" },
                    boxSize: { name: "Box size" },
                    style: { name: "Style" },
                    atrLength: { name: "ATR length" },
                    wicks: { name: "Wicks" },
                  },
                },
                pbStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  upColorProjection: "rgba( 169, 220, 195, 1)",
                  downColorProjection: "rgba( 245, 166, 174, 1)",
                  borderUpColorProjection: "rgba( 169, 220, 195, 1)",
                  borderDownColorProjection: "rgba( 245, 166, 174, 1)",
                  inputs: { source: "close", lb: 3 },
                  inputInfo: {
                    source: { name: "Source" },
                    lb: { name: "Number of line" },
                  },
                },
                kagiStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  upColorProjection: "rgba( 169, 220, 195, 1)",
                  downColorProjection: "rgba( 245, 166, 174, 1)",
                  inputs: {
                    source: "close",
                    style: "ATR",
                    atrLength: 14,
                    reversalAmount: 1,
                  },
                  inputInfo: {
                    source: { name: "Source" },
                    style: { name: "Style" },
                    atrLength: { name: "ATR length" },
                    reversalAmount: { name: "Reversal amount" },
                  },
                },
                pnfStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  upColorProjection: "rgba( 169, 220, 195, 1)",
                  downColorProjection: "rgba( 245, 166, 174, 1)",
                  inputs: {
                    sources: "Close",
                    reversalAmount: 3,
                    boxSize: 1,
                    style: "ATR",
                    atrLength: 14,
                    oneStepBackBuilding: false,
                  },
                  inputInfo: {
                    sources: { name: "Source" },
                    boxSize: { name: "Box size" },
                    reversalAmount: { name: "Reversal amount" },
                    style: { name: "Style" },
                    atrLength: { name: "ATR length" },
                    oneStepBackBuilding: { name: "One step back building" },
                  },
                },
                baselineStyle: {
                  baselineColor: "rgba( 117, 134, 150, 1)",
                  topFillColor1: "rgba( 38, 166, 154, 0.28)",
                  topFillColor2: "rgba( 38, 166, 154, 0.05)",
                  bottomFillColor1: "rgba( 239, 83, 80, 0.05)",
                  bottomFillColor2: "rgba( 239, 83, 80, 0.28)",
                  topLineColor: "rgba( 38, 166, 154, 1)",
                  bottomLineColor: "rgba( 239, 83, 80, 1)",
                  topLineWidth: 2,
                  bottomLineWidth: 2,
                  priceSource: "close",
                  transparency: 50,
                  baseLevelPercentage: 50,
                },
                rangeStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  thinBars: true,
                  upColorProjection: "rgba( 169, 220, 195, 1)",
                  downColorProjection: "rgba( 245, 166, 174, 1)",
                  inputs: { range: 10, phantomBars: false },
                  inputInfo: {
                    range: { name: "Range" },
                    phantomBars: { name: "Phantom bars" },
                  },
                },
                symbol: "LINK/USDT",
                shortName: "LINK/USDT",
                timeframe: "",
                onWidget: false,
                interval: "15",
                currencyId: null,
                showSessions: false,
                priceAxisProperties: {
                  autoScale: true,
                  autoScaleDisabled: false,
                  lockScale: false,
                  percentage: false,
                  percentageDisabled: false,
                  log: false,
                  logDisabled: false,
                  alignLabels: true,
                  isInverted: false,
                  indexedTo100: false,
                },
              },
              zorder: -1,
              haStyle: { studyId: "BarSetHeikenAshi@tv-basicstudies-60" },
              renkoStyle: { studyId: "BarSetRenko@tv-prostudies-40" },
              pbStyle: { studyId: "BarSetPriceBreak@tv-prostudies-34" },
              kagiStyle: { studyId: "BarSetKagi@tv-prostudies-34" },
              pnfStyle: { studyId: "BarSetPnF@tv-prostudies-34" },
              rangeStyle: { studyId: "BarSetRange@tv-basicstudies-72" },
              currencyId: null,
            },
            {
              type: "study_Volume",
              id: "LgqV8e",
              state: {
                styles: {
                  vol: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 65,
                    visible: true,
                    color: "#000080",
                    histogramBase: 0,
                    joinPoints: false,
                    title: "Volume",
                  },
                  vol_ma: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 65,
                    visible: false,
                    color: "#0496ff",
                    histogramBase: 0,
                    joinPoints: false,
                    title: "Volume MA",
                  },
                },
                palettes: {
                  volumePalette: {
                    colors: {
                      "0": {
                        color: "#eb4d5c",
                        width: 1,
                        style: 0,
                        name: "Falling",
                      },
                      "1": {
                        color: "#53b987",
                        width: 1,
                        style: 0,
                        name: "Growing",
                      },
                    },
                  },
                },
                inputs: { showMA: false, length: 20, col_prev_close: false },
                precision: "default",
                bands: {},
                area: {},
                graphics: {},
                showInDataWindow: true,
                visible: true,
                showStudyArguments: true,
                plots: {
                  "0": { id: "vol", type: "line" },
                  "1": {
                    id: "volumePalette",
                    palette: "volumePalette",
                    target: "vol",
                    type: "colorer",
                  },
                  "2": { id: "vol_ma", type: "line" },
                },
                _metainfoVersion: 50,
                isTVScript: false,
                isTVScriptStub: false,
                is_hidden_study: false,
                transparency: 65,
                description: "Volume",
                shortDescription: "Volume",
                is_price_study: false,
                id: "Volume@tv-basicstudies",
                description_localized: "Volume",
                shortId: "Volume",
                packageId: "tv-basicstudies",
                version: "1",
                fullId: "Volume@tv-basicstudies-1",
                productId: "tv-basicstudies",
                name: "Volume@tv-basicstudies",
                _serverMetaInfoVersion: 15,
                format: { type: "volume" },
              },
              zorder: -2,
              metaInfo: {
                palettes: {
                  volumePalette: {
                    colors: {
                      "0": { name: "Falling" },
                      "1": { name: "Growing" },
                    },
                  },
                },
                inputs: [
                  {
                    id: "showMA",
                    name: "show MA",
                    defval: false,
                    type: "bool",
                    isHidden: true,
                  },
                  {
                    id: "length",
                    name: "MA Length",
                    defval: 20,
                    type: "integer",
                    min: 1,
                    max: 2000,
                  },
                  {
                    defval: false,
                    id: "col_prev_close",
                    name: "Color based on previous close",
                    type: "bool",
                  },
                ],
                plots: [
                  { id: "vol", type: "line" },
                  {
                    id: "volumePalette",
                    palette: "volumePalette",
                    target: "vol",
                    type: "colorer",
                  },
                  { id: "vol_ma", type: "line" },
                ],
                graphics: {},
                defaults: {
                  styles: {
                    vol: {
                      linestyle: 0,
                      linewidth: 1,
                      plottype: 5,
                      trackPrice: false,
                      transparency: 65,
                      visible: true,
                      color: "#000080",
                    },
                    vol_ma: {
                      linestyle: 0,
                      linewidth: 1,
                      plottype: 0,
                      trackPrice: false,
                      transparency: 65,
                      visible: false,
                      color: "#0496FF",
                    },
                  },
                  palettes: {
                    volumePalette: {
                      colors: {
                        "0": { color: "#eb4d5c", width: 1, style: 0 },
                        "1": { color: "#53b987", width: 1, style: 0 },
                      },
                    },
                  },
                  inputs: { showMA: false, length: 20, col_prev_close: false },
                },
                _metainfoVersion: 50,
                isTVScript: false,
                isTVScriptStub: false,
                is_hidden_study: false,
                transparency: 65,
                styles: {
                  vol: { title: "Volume", histogramBase: 0 },
                  vol_ma: { title: "Volume MA", histogramBase: 0 },
                },
                description: "Volume",
                shortDescription: "Volume",
                is_price_study: false,
                id: "Volume@tv-basicstudies-1",
                description_localized: "Volume",
                shortId: "Volume",
                packageId: "tv-basicstudies",
                version: "1",
                fullId: "Volume@tv-basicstudies-1",
                productId: "tv-basicstudies",
                name: "Volume@tv-basicstudies",
                _serverMetaInfoVersion: 15,
                format: { type: "volume" },
              },
            },
          ],
          leftAxisesState: [],
          rightAxisesState: [
            {
              state: {
                id: "GmUHDTYLPfS2",
                m_priceRange: { m_maxValue: 42.046, m_minValue: 29.453 },
                m_isAutoScale: true,
                m_isPercentage: false,
                m_isIndexedTo100: false,
                m_isLog: false,
                m_isLockScale: false,
                m_isInverted: false,
                m_height: 843,
                m_topMargin: 0.1,
                m_bottomMargin: 0.08,
                alignLabels: true,
              },
              sources: ["JBYrS4"],
            },
          ],
          overlayPriceScales: {
            LgqV8e: {
              id: "aZUJx6VObo5X",
              m_priceRange: { m_maxValue: 2651042.83, m_minValue: 0 },
              m_isAutoScale: true,
              m_isPercentage: false,
              m_isIndexedTo100: false,
              m_isLog: false,
              m_isLockScale: false,
              m_isInverted: false,
              m_height: 843,
              m_topMargin: 0.75,
              m_bottomMargin: 0,
              alignLabels: true,
            },
          },
          stretchFactor: 2000,
          mainSourceId: "JBYrS4",
          priceScaleRatio: null,
        },
      ],
      timeScale: { m_barSpacing: 6, m_rightOffset: 10 },
      chartProperties: {
        paneProperties: {
          backgroundType: "solid",
          background: "rgba(19, 23, 34, 1)",
          backgroundGradientStartColor: "#ffffff",
          backgroundGradientEndColor: "#ffffff",
          vertGridProperties: { color: "rgba(42, 46, 57, 1)", style: 0 },
          horzGridProperties: { color: "rgba(42, 46, 57, 1)", style: 0 },
          crossHairProperties: {
            color: "#758696",
            style: 2,
            transparency: 0,
            width: 1,
          },
          topMargin: 10,
          bottomMargin: 8,
          axisProperties: {
            autoScale: true,
            autoScaleDisabled: false,
            lockScale: false,
            percentage: false,
            percentageDisabled: false,
            indexedTo100: false,
            log: false,
            logDisabled: false,
            alignLabels: true,
            isInverted: false,
          },
          legendProperties: {
            showStudyArguments: true,
            showStudyTitles: true,
            showStudyValues: true,
            showSeriesTitle: true,
            showSeriesOHLC: true,
            showLegend: true,
            showBarChange: true,
            showBackground: true,
            backgroundTransparency: 50,
            wrapText: false,
          },
        },
        scalesProperties: {
          backgroundColor: "#ffffff",
          lineColor: "rgba(120, 123, 134, 1)",
          textColor: "rgba(120, 123, 134, 1)",
          fontSize: 12,
          scaleSeriesOnly: false,
          showSeriesLastValue: true,
          seriesLastValueMode: 1,
          showSeriesPrevCloseValue: false,
          showStudyLastValue: false,
          showSymbolLabels: false,
          showStudyPlotLabels: false,
          showBidAskLabels: false,
          showPrePostMarketPriceLabel: true,
          showFundamentalNameLabel: false,
          showFundamentalLastValue: false,
          barSpacing: 6,
          showCurrency: true,
        },
        chartEventsSourceProperties: {
          visible: true,
          futureOnly: true,
          breaks: {
            color: "rgba(85, 85, 85, 1)",
            visible: false,
            style: 2,
            width: 1,
          },
        },
        priceScaleSelectionStrategyName: "auto",
      },
      lineToolsGroups: { groups: [] },
      version: 2,
      timezone: "America/Phoenix",
      sessions: {
        properties: {
          graphics: {
            backgrounds: {
              outOfSession: {
                color: "#2196F3",
                transparency: 92,
                visible: false,
              },
              preMarket: { color: "#FF9800", transparency: 92, visible: false },
              postMarket: {
                color: "#2196F3",
                transparency: 92,
                visible: false,
              },
            },
            vertlines: {
              sessBreaks: {
                color: "#4985e7",
                style: 2,
                visible: false,
                width: 1,
              },
            },
          },
        },
      },
      shouldBeSavedEvenIfHidden: false,
      chartId: "JB9214j8dAwf",
    },
  ],
};
