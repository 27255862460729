import React from "react";
import { fixed } from "../utils";
import { useCalculated } from "../utils/state";
import {
  EuiBadge,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiText,
} from "@elastic/eui";

interface DCAProps {}

const DCA: React.FC<DCAProps> = () => {
  const { dca } = useCalculated();
  return (
    <>
      {dca.map((d, idx) => (
        <EuiFlexGroup key={idx} alignItems="center" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiBadge>{d.percent}%</EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiProgress value={d.percent} max={100} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiCopy textToCopy={fixed(d.position)}>
              {(copy) => (
                <EuiText
                  className="mono copyable"
                  onClick={copy}
                >
                  {fixed(d.position)}
                </EuiText>
              )}
            </EuiCopy>
          </EuiFlexItem>
        </EuiFlexGroup>
      ))}
    </>
  );
};

export default DCA;
