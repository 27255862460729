import { Fragment } from "react";
import {
  EuiButton,
  EuiText,
  EuiBadge,
  EuiSpacer,
  EuiTabs,
  EuiTab,
  EuiTitle,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiFlyoutFooter,
} from "@elastic/eui";
import { useAtom } from "jotai";
import { helpTabId, showHelpAtom } from "../utils/state";
import Formula from "./Formula";

const glossary = [
  {
    name: "Total Capital",
    description: "The total amount of capital in your trading account.",
  },
  {
    name: "Risk",
    description: "The percentage of your total capital you are willing to risk in this trade.",
  },
  {
    name: "Long",
    description:
      "A buying position that enables the trader to profit if the price of an asset increases.",
  },
  {
    name: "Short",
    description:
      "A selling position that enables a trader to profit if the price of an asset decreases.",
  },
  {
    name: "Leverage",
    description:
      "The proportion of your trade that will be paid for with borrowed funds. If you are using 2x leverage, you will be funding half of the trade. If you are using 25x leverage you will be funding 1/25 of the trade.",
  },
  {
    name: "Entry",
    description: "The price at which you enter the trade.",
  },
  {
    name: "Stop Loss",
    description:
      "A price level you can set on a position which will, once reached, close the position and prevent any further loss.",
  },
  {
    name: "Exit",
    description:
      'The price at which you exit your trade. Can be referred to as your take profit or "TP".',
  },
  {
    name: "Risked Capital",
    description: "What you stand to lose if given stop loss is reached.",
  },
  {
    name: "Margin",
    description:
      "Margin is the portion of your own funds that you put into a trade. Keep in mind that depending on what margin mode you are using, your losses may not be limited to this margin.",
  },
  {
    name: "Position Size",
    description: "The size, or quantity, of the asset/coin (not currency) for this position.",
  },
  {
    name: "Liquidation",
    description:
      "The forced closure of a trade due to the available margin being exhausted, forcing the exchange to close the trade to stop losses and mitigate risk.",
  },
  {
    name: "PnL",
    description: "Profit and Loss. Shows what you stand to gain or lose with the inputs provided.",
  },
  {
    name: "Risk / Reward",
    description: "The ratio of potential profit of the trade to its potential loss.",
  },
  {
    name: "Return on Equity (ROE)",
    description: "The ratio of total profit to margin (your cost).",
  },
  {
    name: "Percent Change",
    description:
      "The percent increase of the price of the asset. Determined by provided entry and exit prices.",
  },
];

const calculations = [
  {
    name: "Risked Capital",
    tex: "riskedCapital = totalCapital \\times risk",
  },
  {
    name: "Position Size",
    tex: "positionSize = \\frac{riskedCapital}{entry - stopLoss}",
  },
  {
    name: "Margin",
    tex: "margin = \\frac{positionSize \\times entry}{leverage}",
  },
  {
    name: "PnL",
    tex: "pnl = positionSize \\times (exit - entry)",
  },
  {
    name: "Risk / Reward",
    tex: "riskReward = \\frac{pnl}{riskedCapital}",
  },
  {
    name: "ROE",
    tex: "roe = \\frac{PnL}{margin} \\times 100",
  },
];

const tabs = [
  {
    name: "Glossary",
  },
  {
    name: "Calculations",
  },
];

const Help: React.FC = () => {
  const [showHelp, setShowHelp] = useAtom(showHelpAtom);
  const [tabId, setTabId] = useAtom(helpTabId);

  if (!showHelp) {
    return null;
  }

  return (
    <EuiFlyout onClose={() => setShowHelp(false)} ownFocus>
      <EuiFlyoutHeader>
        <EuiTitle>
          <h1>Help</h1>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiTabs>
          {tabs.map(({ name }) => (
            <EuiTab isSelected={tabId === name} onClick={() => setTabId(name)}>
              {name}
            </EuiTab>
          ))}
        </EuiTabs>
      </EuiFlyoutHeader>

      <EuiFlyoutBody>
        {tabId === "Glossary" && (
          <EuiText>
            <ul>
              {glossary.map(({ name, description }) => (
                <li key={name}>
                  <EuiBadge>{name}</EuiBadge>
                  <EuiText>{description}</EuiText>
                  <EuiSpacer size="s" />
                </li>
              ))}
            </ul>
          </EuiText>
        )}
        {tabId === "Calculations" && (
          <ul>
            {calculations.map(({ name, tex }) => (
              // <li key={name}>
              <Fragment key={name}>
                {/* <EuiBadge style={{ marginRight: 10 }}>{name}</EuiBadge> */}
                <Formula tex={tex} />
                <EuiSpacer size="s" />
              </Fragment>
              // </li>
            ))}
          </ul>
        )}
      </EuiFlyoutBody>

      <EuiFlyoutFooter>
        <EuiButton onClick={() => setShowHelp(false)} fill>
          Close
        </EuiButton>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
};

export default Help;
