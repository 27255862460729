import { useEffect, useRef, useState } from "react";
import { binanceFeed } from "../../utils/chart/datafeed";
import {
  widget,
  IChartingLibraryWidget,
  ResolutionString,
  IOrderLineAdapter,
  EntityId,
  ISubscription,
} from "../../vendor/tradingview/charting_library";
import {
  symbolAtom,
  intervalAtom,
  useCalculator,
  useCalculated,
} from "../../utils/state";
import defaultChart from "../../utils/chart/default_chart";
import { useAtom } from "jotai";
import { euiPaletteForStatus } from "@elastic/eui";

interface TradingViewProps {}

const ID = "trading-view";
const LINE_LENGTH = 15;

let chart: IChartingLibraryWidget;
let entryline: IOrderLineAdapter | null;
let stopline: IOrderLineAdapter | null;
let exitline: IOrderLineAdapter | null;
let liqId: EntityId | null;
let symbolSub: ISubscription<any>;
let intervalSub: ISubscription<any>;

const TradingView: React.FC<TradingViewProps> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [chartReady, setChartReady] = useState(false);
  const [interval, setInterval] = useAtom(intervalAtom);
  const [symbol, setSymbol] = useAtom(symbolAtom);
  const [values, setValues] = useCalculator();
  const calc = useCalculated();

  const colors = euiPaletteForStatus(3);

  useEffect(() => {
    const persisted = localStorage.getItem("_cpos.chart");
    chart = new widget({
      symbol: symbol,
      container_id: ID,
      locale: "en",
      interval: interval as ResolutionString,
      library_path: "/tradingview/",
      theme: "Dark",
      autosize: true,
      auto_save_delay: 1,
      saved_data: persisted ? JSON.parse(persisted) : defaultChart,
      datafeed: binanceFeed,
    });
    chart.onChartReady(() => {
      setChartReady(true);
      chart.subscribe("onAutoSaveNeeded", () => {
        chart.save((data) =>
          localStorage.setItem("_cpos.chart", JSON.stringify(data))
        );
      });
      const active = chart.activeChart();
      if (symbolSub) {
        symbolSub.unsubscribeAll(null);
      }
      symbolSub = active.onSymbolChanged();
      symbolSub.subscribe(null, () => {
        const activeSymbol = active.symbol();
        setSymbol(activeSymbol);
      });
      if (intervalSub) {
        intervalSub.unsubscribeAll(null);
      }
      intervalSub = active.onIntervalChanged();
      intervalSub.subscribe(null, () => {
        const { interval } = chart.symbolInterval();
        setInterval(interval);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartReady && values) {
      const active = chart.activeChart();

      if (entryline) {
        entryline.remove();
        entryline = null;
      }
      if (stopline) {
        stopline.remove();
        stopline = null;
      }
      if (exitline) {
        exitline.remove();
        exitline = null;
      }
      if (values.entry) {
        entryline = active.createOrderLine({ disableUndo: true });
        entryline.setPrice(Number(values.entry));
        entryline.setText("ENTRY");
        entryline.setExtendLeft(true);
        entryline.setEditable(true);
        entryline.setLineColor(colors[0]);
        entryline.setLineStyle(2);
        entryline.setBodyBorderColor(colors[0]);
        entryline.setQuantityBackgroundColor(colors[0]);
        entryline.setQuantityBorderColor(colors[0]);
        entryline.setBodyTextColor(colors[0]);
        entryline.setBodyBackgroundColor("#FFFFFF");
        entryline.setLineLength(LINE_LENGTH);
        if (calc.positionSize) {
          entryline.setQuantity(calc.positionSize.toFixed(2));
        }
        entryline.onMove(() => {
          setValues({ entry: entryline?.getPrice().toString() });
        });
      }

      if (values.stop) {
        stopline = active.createOrderLine({ disableUndo: true });
        stopline.setPrice(Number(values.stop));
        stopline.setText("STOP LOSS");
        stopline.setEditable(true);
        stopline.setLineColor(colors[2]);
        stopline.setLineStyle(2);
        stopline.setBodyBorderColor(colors[2]);
        stopline.setQuantityBackgroundColor(colors[2]);
        stopline.setQuantityBorderColor(colors[2]);
        stopline.setBodyTextColor(colors[2]);
        stopline.setBodyBackgroundColor("#FFFFFF");
        stopline.setLineLength(LINE_LENGTH);
        if (calc.positionSize) {
          stopline.setQuantity(calc.positionSize.toFixed(2));
        }
        stopline.onMove(() => {
          setValues({ stop: stopline?.getPrice().toString() });
        });
      }

      if (values.exit) {
        exitline = active.createOrderLine({ disableUndo: true });
        exitline.setPrice(Number(values.exit));
        exitline.setText("EXIT");
        exitline.setEditable(true);
        exitline.setLineColor(colors[0]);
        exitline.setBodyBorderColor(colors[0]);
        exitline.setLineStyle(0);
        exitline.setQuantityBackgroundColor(colors[0]);
        exitline.setQuantityBorderColor(colors[0]);
        exitline.setBodyTextColor(colors[0]);
        exitline.setBodyBackgroundColor("#FFFFFF");
        exitline.setLineLength(LINE_LENGTH);
        if (calc.positionSize) {
          exitline.setQuantity(calc.positionSize.toFixed(2));
        }
        exitline.onMove(() => {
          setValues({ exit: exitline?.getPrice().toString() });
        });
      }

      setTimeout(() => {
        if (calc.liquidation) {
          try {
            if (liqId) {
              active.removeEntity(liqId, { disableUndo: true });
              liqId = null;
            }
            liqId = active.createShape(
              { price: calc.liquidation, time: 0 },
              {
                shape: "horizontal_line",
                text: "LIQUIDATION",
                lock: true,
                disableSelection: true,
                disableSave: true,
                disableUndo: true,
                overrides: {
                  showLabel: true,
                  linecolor: colors[2],
                  textcolor: colors[2],
                },
              }
            );
          } catch (err) {
            console.error("Error adding liquidation point", err);
          }
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartReady, values, calc, symbol, setInterval, setValues]);

  useEffect(() => {
    if (chartReady && chart.activeChart().symbol() !== symbol) {
      chart.activeChart().setSymbol(symbol, () => {});
    }
  }, [chartReady, symbol]);

  return (
    <div style={{ height: "100%", minHeight: 600 }} id={ID} ref={ref}></div>
  );
};

export default TradingView;
