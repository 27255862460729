import { atom } from "jotai";
import { proxy, snapshot, subscribe } from "valtio/vanilla";
import create from "zustand/vanilla";
import { persist } from "zustand/middleware";
import { State } from "zustand";

export function persistedAtom(key: string, defaultValue: string) {
  const persisted = atom<string, string>(
    localStorage.getItem(key) || defaultValue,
    (_get, set, update) => {
      localStorage.setItem(key, update);
      set(persisted, update);
    }
  );
  return persisted;
}

export function persistedProxy<T extends object>(key: string, defaultValue: T) {
  const jsonStr = localStorage.getItem(key);
  const obj = jsonStr ? JSON.parse(jsonStr) : defaultValue;
  const proxied = proxy<T>(obj);
  subscribe(proxied, () => {
    const snap = snapshot(proxied);
    console.log("saving", snap);
    localStorage.setItem(key, JSON.stringify(snap));
  });
  return proxied;
}

export function persistedState<T extends State>(key: string, defaultValue: T) {
  const jsonStr = localStorage.getItem(key);
  const obj: T = jsonStr ? JSON.parse(jsonStr).state : defaultValue; 
  return create<T>(persist<T>(() => obj, { name: key }));
}

export function tPersistedAtom<T extends string | boolean | number | number[] | null>(
  key: string,
  defaultValue: T
) {
  const persisted = atom<T, T>(
    () => {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : defaultValue;
    },
    (_get, set, update) => {
      localStorage.setItem(key, JSON.stringify(update));
      set(persisted, update);
    }
  );
  return persisted;
}

export function getNumeric(num: string | null): number | null {
  return num && !isNaN(Number(num)) ? Number(num) : null;
}