import { useAtom } from "jotai";
import { showSettingsAtom } from "../utils/state";
import React from "react";
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import DCASetting from "./DCASetting";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const [showSettings, setShowSettings] = useAtom(showSettingsAtom);
  // const [showChart, setShowChart] = useAtom(showChartAtom);
  // const [auth, setAuth] = useAtom(userAtom);
  // const setShowSignIn = useUpdateAtom(showSignInAtom);
  // const setShowSignUp = useUpdateAtom(showSignUpAtom);

  // const signOut = async () => {
  //   try {
  //     await userbase.signOut();
  //     setAuth({ ...auth, user: null });
  //     setShowSettings(false);
  //   } catch (err) {
  //     alert(err.message);
  //   }
  // };

  return showSettings ? (
    <EuiFlyout
      size="s"
      onClose={() => setShowSettings(false)}
      ownFocus
      paddingSize="l"
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 className="mono">calc.trade</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        {/* {auth.user ? (
          <EuiButton color="text" onClick={signOut}>
            Sign out
          </EuiButton>
        ) : (
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiButton
                onClick={() => {
                  setShowSettings(false);
                  setShowSignUp(true);
                }}
              >
                Sign Up
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonEmpty
                color="text"
                onClick={() => {
                  setShowSettings(false);
                  setShowSignIn(true);
                }}
              >
                Sign In
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        <EuiSpacer /> */}
        <EuiTitle size="s">
          <h3>Settings</h3>
        </EuiTitle>
        {/* <EuiSpacer />
        <EuiSwitch
          id="show-chart"
          checked={showChart}
          onChange={(e: any) => setShowChart(e.target.checked)}
          // labelPlacement={LABEL_PLACEMENT.right}
          // checkmarkType={STYLE_TYPE.toggle_round}
          label="Show Chart"
        /> */}
        <EuiSpacer />
        <DCASetting />
      </EuiFlyoutBody>
    </EuiFlyout>
  ) : null;
};

export default Settings;
